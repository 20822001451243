import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducer';

const useGlobalKeyListener = () => {
    const store = useSelector((state: RootState) => state);
    const storeRef = useRef(store); // Use a ref to store the latest state
  
    useEffect(() => {
      storeRef.current = store; // Update ref whenever the store changes
    }, [store]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
        const isToolRegisteruser = storeRef.current.user?.me?.user_clearence_groups?.includes("Tool Register") || false;

        if(isToolRegisteruser){
            if (event.key === 'F9') {
              window.location.href = '/#/tool-register?value=0';
            } else if (event.key === 'F10') {
              window.location.href = '/#/tool-register?value=1';
            }
        }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default useGlobalKeyListener;