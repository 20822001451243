import { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';

// This is a linear indicator that can be used on top of a mainpage and then it's positioned right below main header and fills the entire width.
// Can be adapted to other uses, but then we need to send in props and change to inline styling
const useStyles = makeStyles(theme => ({
  loadingBar: {
    position: 'relative',
    bottom: '25px',
  }
}));

type LinearProgressUIProps = {}

const LinearProgressUI: FunctionComponent<LinearProgressUIProps> = () => {
  const classes = useStyles();
  return (
    <LinearProgress className={classes.loadingBar} />
  );
};
export default LinearProgressUI