import React, { FunctionComponent, useState } from 'react';
import { CalendarNav, Eventcalendar, MbscEventcalendarView, MbscResource, Button, CalendarToday } from '@mobiscroll/react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ShoppingCart, Person, Visibility } from '@mui/icons-material';
import { Tooltip, Typography } from './../../MaterialUiComponents/';
import { makeStyles } from '@mui/styles';
import { formatFloatNumberToHoursMinString } from '../../utils/dateUtils';
import { EventInfo } from '../../types/mobiScroll';
import SoapIcon from '@mui/icons-material/Soap';
import { DateTime } from 'luxon';

const useStyles = makeStyles(theme => ({
    calendarWrapper: {
        height: 'calc(100vh - 220px)',
    },
    addSpace: {
        marginRight: '3px',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
}));

type Props = {
    calendarTasks: EventInfo[],
    resources: Array<string>,
    onEventClick: Function,
    isShortTermView: boolean,
}

const MobiscrollCalendar: FunctionComponent<Props> = React.memo(({ calendarTasks, resources, onEventClick, isShortTermView }) => {
    const classes = useStyles();
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [viewType, setViewType] = useState<"week" | "month">("week");

    const view = React.useMemo<MbscEventcalendarView>(() => {
        if (viewType === "week") {
            return {
                timeline: {
                    type: "week",
                    timeCellStep: 240,
                    weekNumbers: true,
                    timeLabelStep: 240,
                    timeLabelFormat: "HH:mm",
                },
            };
        } else {
            return {
                calendar: {
                    type: "month",
                    labels: true,
                },

            };
        }
    }, [viewType]);


    const calendarResources = Array<MbscResource>();
    resources.sort().map((row) => {
        calendarResources.push({
            id: row,
            name: row,
            color: '#e20000',
        })
    });

    const navigateToPrevPage = () => {
        const nextWeek = new Date(currentDate);
        if(viewType === "week") {
            nextWeek.setDate(nextWeek.getDate() - 7);
        }
        else {
            nextWeek.setMonth(nextWeek.getMonth() - 1);
        }
        setCurrentDate(nextWeek);
    };

    const navigateToNextPage = () => {
        const nextWeek = new Date(currentDate);
        if(viewType === "week") {
            nextWeek.setDate(nextWeek.getDate() + 7);
        }
        else {
            nextWeek.setMonth(nextWeek.getMonth() + 1);
        }
        setCurrentDate(nextWeek);
    };

    const navigateToToday = () => {
        const today = new Date();
        setCurrentDate(today);
    };
    

    const handleViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setViewType(event.target.value as "week" | "month");
    };

    const customHeader = () => {
        return (
            <>
                <CalendarNav className="md-custom-header-nav" />
                <div className="md-custom-header-controls">
                    <Button onClick={navigateToPrevPage} icon="material-arrow-back" color='primary' variant="flat" className="md-custom-header-button"></Button>
                    <Button onClick={navigateToToday} color='primary' variant="flat" className="md-custom-header-button"> <CalendarToday  className="md-custom-header-today" /></Button>
                    <Button onClick={navigateToNextPage} icon="material-arrow-forward" color='primary' variant="flat" className="md-custom-header-button"></Button>
                </div>
                <div style={{ display: 'flex', marginLeft: '50px' }} >
                    <Typography text={"Complete"} align='center' variant='h6' fontWeight="bold" textColor='#497523' margin={[0, 4, 0, 0]} />
                    <Typography text={"ACTV"} align='center' variant='h6' fontWeight="bold" textColor='#f5b53f' margin={[0, 4, 0, 0]} />
                    <Typography text={"COMMIT"} align='center' variant='h6' fontWeight="bold" textColor='#e4b7b7' margin={[0, 4, 0, 0]} />
                    <Typography text={"IN WORK"} align='center' variant='h6' fontWeight="bold" textColor='#506e9f' margin={[0, 4, 0, 0]} />
                </div>
                {isShortTermView && <div style={{ display: 'flex' }}>
                    <SoapIcon color={'primary'} />
                    <Typography text={"Cleaning tasks"} align='center' variant='h6' fontWeight="bold" margin={[0.5, 4, 0, 0]} />
                </div>}
                {isShortTermView && <div style={{ display: 'flex' }}>
                    <ShoppingCart color={'primary'} />
                    <Typography text={"Parts Avail"} align='center' variant='h6' fontWeight="bold" margin={[0.5, 4, 0, 0]} />
                </div>}
                {isShortTermView && <div style={{ display: 'flex' }}>
                    <ShoppingCart color={'error'} />
                    <Typography text={"Parts Not-Avail"} align='center' variant='h6' fontWeight="bold" margin={[0.5, 4, 0, 0]} />
                </div>}
                {isShortTermView && <div style={{ display: 'flex' }}>
                    <Visibility color={'primary'} />
                    <Typography text={"Certification required"} align='center' variant='h6' fontWeight="bold" margin={[0.5, 4, 0, 0.5]} />
                </div>}

                {isShortTermView === false &&
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginLeft: "20px",
                    }}>
                    
                        <FormControl component="fieldset">

                            <RadioGroup row value={viewType} onChange={handleViewChange}>
                                <FormControlLabel
                                    value="week"
                                    control={<Radio />}
                                    label="Week"
                                />
                                <FormControlLabel
                                    value="month"
                                    control={<Radio />}
                                    label="Month"
                                />
                            </RadioGroup>
                        </FormControl>
                </div>
                }
            </>
        );
    };

    const getTruncatedTitle = (title: string, startDate: Date, endDate: Date, maxChars: number = 13): string => {
        // Parse dates in case they're strings
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Calculate the duration in hours
        const durationInHours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);

        // Truncate the title if the event is less than 4 hours
        if (durationInHours <= 4 && title.length > maxChars) {
            return title.slice(0, maxChars) + '...';
        }


        return title;
    };


    const myScheduleEvent = React.useCallback((event: any) => {
        const title = event.title;
        const packet = event.original;
        event.color = "#004776";
        return (
            <Grid >
                <Grid container >
                    <Grid container id="icons">
                        {packet.parts_amounts > 0 && isShortTermView && (
                            <Grid>
                                <Tooltip title={`${packet.parts_amounts} parts`} type="primary" placement="top-start">
                                    <ShoppingCart style={{ cursor: 'pointer' }} color={packet.parts_amounts === packet.parts_available ? 'primary' : 'error'} />
                                </Tooltip>
                            </Grid>
                        )}
                        {packet.wo_includes_clean_task === 1 && isShortTermView && (
                            <Grid>
                                <Tooltip title={`Cleaning task`} type="primary" placement="top-start" >
                                    <SoapIcon style={{ cursor: 'pointer' }} color={'primary'} />
                                </Tooltip>
                            </Grid>
                        )}
                        {packet.assigned_employees.length > 0 && isShortTermView && (
                            <Grid>
                                <Tooltip title={`${packet.assigned_employees.length} employees`} type="primary" placement="top-start">
                                    <Person style={{ cursor: 'pointer' }} color="primary" />
                                </Tooltip>
                            </Grid>
                        )}
                        {packet.need_cert === true && isShortTermView && (
                            <Grid>
                                <Tooltip title={`Certification required`} type="primary" placement="top-start" >
                                    <Visibility style={{ cursor: 'pointer' }} color={'primary'} />
                                </Tooltip>
                            </Grid>
                        )}
                        <p>{packet.need_cert}</p>
                    </Grid>
                    <Grid container style={{ width: '100%' }}>

                        <b className={classes.addSpace}>{getTruncatedTitle(title, packet.start, packet.end)}</b>
                        {/*  This code was outcommented in DDINT-3756, will be inroduced later
                        
                        {(packet.mt_man_hours > 0 || packet.mxi_man_hours > 0) && <b className={classes.addSpace}> Man hours:</b>}
                        {packet.mt_man_hours > 0 &&
                            <b className={classes.addSpace}> MT: {formatFloatNumberToHoursMinString(packet.mt_man_hours)}
                                {packet.mt_man_hours > 0 && packet.mxi_man_hours > 0 && ', '}
                            </b>}
                        {packet.mxi_man_hours > 0 && <b> MXi: {formatFloatNumberToHoursMinString(packet.mxi_man_hours)} </b>} */}

                        {packet.mt_man_hours > 0 &&
                            <b className={classes.addSpace}> Man hours: {formatFloatNumberToHoursMinString(packet.mt_man_hours)}
                                {packet.mt_man_hours > 0 && packet.mxi_man_hours > 0}
                            </b>}

                        {isShortTermView === false && 
                            <b >Man hours: MXI {packet.mxi_man_hours_days > 0 ? packet.mxi_man_hours_days : 'N/A'}
                            </b>
                        }

                    </Grid>
                    <Grid style={{ width: '100%' }}>
                        {isShortTermView && 
                            <p className={classes.addSpace}>{DateTime.fromFormat(packet.start_date_text, 'dd.MM.yyyy HH:mm').toFormat('HH:mm')} - {DateTime.fromFormat(packet.end_date_text, 'dd.MM.yyyy HH:mm').toFormat('HH:mm')}</p>
                        }
                        {isShortTermView === false && 
                            <p className={classes.addSpace}>{DateTime.fromFormat(packet.start_date_text, 'dd.MM.yyyy HH:mm').toFormat('dd.MM.yyyy')} - {DateTime.fromFormat(packet.end_date_text, 'dd.MM.yyyy HH:mm').toFormat('dd.MM.yyyy')}</p>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }, []);

    const renderCalendarEventContent = React.useCallback((event: any) => {
        const packet = event.original;

        return (
            <div>
            <span style={{ fontWeight: 'bold'}}>
                {event.title}
            </span>
            {isShortTermView === false && 
                <b> Man hours: MXI {packet.mxi_man_hours_days > 0 ? packet.mxi_man_hours_days : 'N/A'}
                </b>
            }
            {isShortTermView === false && 
                <span className={classes.addSpace}> {DateTime.fromFormat(packet.start_date_text, 'dd.MM.yyyy HH:mm').toFormat('dd.MM.yyyy')} - {DateTime.fromFormat(packet.end_date_text, 'dd.MM.yyyy HH:mm').toFormat('dd.MM.yyyy')}</span>
            }
        </div>
        );
    }, []);

    return (
        <div className={classes.calendarWrapper}>
            <Eventcalendar
                theme="ios"
                themeVariant="light"
                view={view}
                selectedDate={currentDate}
                data={calendarTasks}
                resources={calendarResources}
                onEventClick={(e: any) => { onEventClick(e) }}
                renderScheduleEventContent={myScheduleEvent} 
                renderLabelContent={renderCalendarEventContent}
                fixedHeader={true}
                renderHeader={customHeader}
                timeFormat="HH:mm"
            />
        </div>
    );
})
export default React.memo(MobiscrollCalendar);