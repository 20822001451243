import { FunctionComponent, useEffect, useState } from "react";
import { HangarChecklistDto } from "../../types/checklist";
import { Checkbox, Grid, Grid2, IconButton, TableCell, TableRow, Button } from "@mui/material";
import { ChecklistTaskDto } from "../../types/checklist";
import { Table, Input, Dialog } from '../../MaterialUiComponents';
import ChecklistApi from "../../api/checklists";
import EditColumn from "../Security/EditColumn";
import { makeStyles } from '@mui/styles';
import { AddCircle, Check, Edit } from '@mui/icons-material';

type ChecklistDetailsModalProps = {
    checklist: HangarChecklistDto | undefined;
    checkListDialogOpen: Function;
    isChecklistAdmin: boolean;
    refreshChecklists: Function;
    setLoading: Function;
}

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    save: {
        color: 'green'
    },
}));

const ChecklistDetailsModal: FunctionComponent<ChecklistDetailsModalProps> = ({ checklist, checkListDialogOpen, isChecklistAdmin, refreshChecklists, setLoading }) => {

    const classes = useStyles();
    const [tasks, setTasks] = useState<ChecklistTaskDto[]>([]);
    const [editRow, setEditRow] = useState<number>(-1);
    const [selectedName, setSelectedName] = useState<string>("");
    const [selectedStatus, setSelectedStatus] = useState<boolean>(false);
    const [createTask, setCreateTask] = useState<boolean>(false);
    const [checklistStatus, setChecklistStatus] = useState<boolean>(false);
    const [closeChecklistDialogOpen, setChecklistDialogOpen] = useState<boolean>(false);
        
    useEffect(() => {
        setLoading(true);
        refresh();
    }, [checklist]);

    const refresh = () => {
        if (checklist !== undefined) {
            setChecklistStatus(checklist?.IsActive);
            ChecklistApi.GetChecklistsTasks(checklist.Id).then(res => {
                setTasks(res.data);
                setLoading(false);
            });
        }
    }

    const onSelectRow = (row: ChecklistTaskDto, index: number) => {
        setEditRow(index);
        setSelectedStatus(row.IsDone);
        setSelectedName(row.TaskName);
    }

    const onChangeStatus = (index: number) => {
        setSelectedStatus(!selectedStatus);
    }

    const onModifyChecklist = (taskId: number) => {
        setLoading(true);
        ChecklistApi.UpdateChecklistTask(taskId, selectedName, selectedStatus).then(res => {
            if (res.status === true) {
                setEditRow(-1);
                refresh();
            }
        })
    }

    const generateTableBody = (row: ChecklistTaskDto, index: number) => {
        return (
        editRow === index ?
                <TableRow key={index}>
                    <TableCell>
                        <Input
                            multiline={true}
                            help_text={""}
                            label_text={"Name"}
                            value={selectedName}
                            onTextChange={(text: string) => setSelectedName(text)}
                            type="text"
                            width={100}
                        />
                    </TableCell>
                    <TableCell width="20">
                        <Checkbox color="primary" disabled={editRow === index ? false : true} checked={editRow === index ? selectedStatus : row.IsDone} onChange={() => onChangeStatus(index)} />
                    </TableCell>
                    <EditColumn
                        abort={true}
                        index={index}
                        saveChanges={() => onModifyChecklist(row.Id)}
                        editRow={index}
                        setEditRow={() => { setEditRow(-1) }}
                    />
                </TableRow>
                :
                <TableRow key={index}>
                    <TableCell>{row.TaskName}</TableCell>
                    <TableCell width="20">{row.IsDone === true ? <Check style={{ color: 'green' }} /> : ""}</TableCell>
                    <TableCell width="20">{(isChecklistAdmin && checklist?.IsActive) && <IconButton onClick={() => onSelectRow(row, index)}><Edit /></IconButton>}</TableCell>
                </TableRow>
        );
    }

    const CreateTask = () => {
        if (checklist !== undefined) {
            setLoading(true);
            ChecklistApi.CreateChecklistTask(checklist.Id, selectedName).then(res => {
                if (res.status === true) {
                    setCreateTask(false);
                    refresh();
                }
            })
        }
    }

    const onCloseChecklist = () => {
        if (checklist !== undefined) {
            setLoading(true);
            ChecklistApi.InactiateChecklist(checklist.Id).then(res => {
                if (res.status === true) {
                    setChecklistStatus(!checklistStatus);
                    checkListDialogOpen(false);
                    refreshChecklists();
                }
            });
        }
    }

    return (
        <Grid2>
            {checklist?.IsActive && 
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                    <Button
                        onClick={() => setChecklistDialogOpen(true)}
                        variant='contained'
                        color={'primary'}
                        style={{ marginTop: '18px' }}
                    >
                        Close Checklist
                    </Button>
                </Grid>
            }
            {(isChecklistAdmin && checklist?.IsActive) && <Grid container direction="column" alignItems="center" style={{ marginTop: '28px' }}>
                <IconButton onClick={() => { setCreateTask(true); setSelectedName(""); setEditRow(-1)}} className={classes.save} >
                    <AddCircle />
                </IconButton>
            </Grid>}
            {createTask &&
                <Grid container direction="row" justifyContent="center" style={{ marginTop: '28px' }}>
                    <Grid style={{ marginRight: '18px' }} item xs={3} md={3} lg={3} xl={3}>
                        <Input multiline={true} help_text={""} label_text={"Name"} value={selectedName} onTextChange={(text: string) => setSelectedName(text)} type="text" width={100} />
                    </Grid>
                    <Grid style={{ marginTop: '8px' }} item xs={1} md={1} lg={1} xl={1}>
                        <Button variant="contained" color="primary" onClick={() => CreateTask()}>Save</Button>
                    </Grid>
                    <Grid style={{ marginRight: '18px', marginTop: '8px' }} item xs={1} md={1} lg={1} xl={1}>
                        <Button variant="contained" color="secondary" onClick={() => setCreateTask(false)}>Abort</Button>
                    </Grid>
                </Grid>
            }
            <Table
                headers={["Task Name", "Done", checklist?.IsActive ? "Edit" : ""]}
                rows={tasks}
                generateBody={(row: ChecklistTaskDto, index: number) => generateTableBody(row, index)}
                pagination={true}
                defaultRowsPerPage={10}
                skipIndexCol={true}
            />
            <Dialog
                visible={closeChecklistDialogOpen}
                max_width={"sm"}
                title={"Are you sure you want to close this checklist?"}
                context={<></>}
                onClose={(status: Boolean) => { status === true ? onCloseChecklist() : setChecklistDialogOpen(false) }}
                strict={false}
                show_options={true}
            />
        </Grid2>
    );
}
export default ChecklistDetailsModal;