import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Tabs,LinearProgress, Typography } from '../../MaterialUiComponents';
import LiteHandover from '../Lite/LiteHandover';
import LineWorkPackageOverview from '../../components/Line/LineWorkPackageOverview';
import { workplaceEnum } from '../../components/Line/LineWorkPackageOverview';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import LineSupervisorPlanedTasks from "../Line/LineSupervisorPlanedTasks";
import TasksApi from '../../api/tasks';
import FlightScheduleView from "../Line/FlightSchedule";
import { FlightSchedule, LineWorkPackages } from '../../types/line';
import taskApi from './../../api/tasks'
import lineApi from '../../api/line';

function ReykjavikLineSupervisor() {
    const [tabValue, setTabValue] = useState(0);
    const dashTails = ["TF-FXG", "TF-FXI", "TF-FXK", "TF-FXA", "TF-FXH", "TF-FXE"];
    const [loading, setLoading] = useState<boolean>(true);
    const [allTails, setAllTails] = useState(Array<string>());

    const store = useSelector((state: RootState) => state);
    const groups = store.user.me.user_clearence_groups;
    const isAdmin = groups.includes("Rvk Line Edit") || groups.includes("Rvk Line View");

    const [flightSchedule, setFlightSchedule] = useState(Array<FlightSchedule>());
    const [orderByText, setOrderByText] = useState("Arrival");
    const [ascending, setAscending] = useState(false);
    const [dataFetchedWhen, setDataFetchedWhen] = useState("");
    const [flightScheduleError, setFlightScheduleError] = useState(false); // set the initial state as error so we do not show the table until we have data

    useEffect(() => {
        setLoading(true);
        TasksApi.GetAllTailNumbers().then(res => {
            setAllTails(res.data);
          });
    }, [tabValue]);

    useEffect(() => {
        setLoading(true);
        lineApi.getFlightSchedule().then(res => {
          setFlightScheduleError(false);
          setLoading(false);
          sortByColumn(orderByText, res.data, !ascending);
          setDataFetchedWhen(res.data_fetched_when);
          if(res.data.length === 0) {
            setFlightScheduleError(true);
          }
        }).catch(err => {
          console.log(err);
          setFlightScheduleError(true);
          setLoading(false);
        });
        taskApi.GetAllTailNumbers().then(res => {
          setAllTails(res.data);
        });
      }, []);
    
      const refresh = () => {
        setLoading(true);
    
        lineApi.getFlightSchedule().then(res => {
          setLoading(false);
          sortByColumn(orderByText, res.data, !ascending);
          setDataFetchedWhen(res.data_fetched_when);
          setFlightScheduleError(false);
          if(res.data.length === 0) {
            setFlightScheduleError(true);
          }
        });
      }
    


       const sortByColumn = (columnName: string, flights: LineWorkPackages[], tempAscending: boolean) => {
          if (columnName === orderByText) {
            setAscending(!tempAscending);
          }
          else {
            setAscending(true);
            setOrderByText(columnName);
          }
          switch (columnName) {
            case "Tail":
              tempAscending ?
                flights.sort(function (a, b) { return b.tail_number > a.tail_number ? 1 : -1 })
                :
                flights.sort(function (a, b) { return a.tail_number > b.tail_number ? 1 : -1 })
              break;
            case "Curr Airport":
              ascending ?
                flights.sort(function (a, b) { return b.current_location > a.current_location ? 1 : -1 })
                :
                flights.sort(function (a, b) { return a.current_location > b.current_location ? 1 : -1 })
              break;
            case "Stand":
              tempAscending ?
                flights.sort(function (a, b) { return b.aircraft_stand_location > a.aircraft_stand_location ? 1 : -1 })
                :
                flights.sort(function (a, b) { return a.aircraft_stand_location > b.aircraft_stand_location ? 1 : -1 })
              break;
            case "Arr Fl, nr.":
              tempAscending ?
                flights.sort(function (a, b) { return b.inbound_departure_airport_code > a.inbound_departure_airport_code ? 1 : -1 })
                :
                flights.sort(function (a, b) { return a.inbound_departure_airport_code > b.inbound_departure_airport_code ? 1 : -1 })
              break;
            case "Arrival":
              flights.sort((a, b) => {
                const dateA = a.arrival_date ? new Date(a.arrival_date).getTime() : Infinity;
                const dateB = b.arrival_date ? new Date(b.arrival_date).getTime() : Infinity;
            
                return tempAscending ? dateA - dateB : dateB - dateA;
              });
              break;
            case "Departure":
              tempAscending ?
                flights.sort(function (a, b) { return new Date(a.departure_date).getTime() - new Date(b.departure_date).getTime() })
                :
                flights.sort(function (b, a) { return new Date(a.departure_date).getTime() - new Date(b.departure_date).getTime() })
              break;
            case "Dep Fl. nr.":
              tempAscending ?
                flights.sort(function (a, b) { return b.outbound_arrival_airport_code > a.outbound_arrival_airport_code ? 1 : -1 })
                :
                flights.sort(function (b, a) { return a.outbound_arrival_airport_code > b.outbound_arrival_airport_code ? 1 : -1 })
              break;
            case "Mechanics":
              tempAscending ?
                flights.sort(function (a, b) { return b.assigned_mechanics > a.assigned_mechanics ? 1 : -1 })
                :
                flights.sort(function (a, b) { return a.assigned_mechanics > b.assigned_mechanics ? 1 : -1 })
              break;
            case "Remarks":
              tempAscending ?
                flights.sort(function (a, b) { return b.remarks > a.remarks ? 1 : -1 })
                :
                flights.sort(function (b, a) { return a.remarks > b.remarks ? 1 : -1 })
              break;
          }
          setFlightSchedule(flights);
        }

    return (
        <Grid>
            {loading && <LinearProgress />}
            <Tabs
                tabNames={["Calendar", "Flight Schedule", "Workpackage Overview",  "Handover",]}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
                tabValue={tabValue}
            />
            {tabValue === 0 && <LineSupervisorPlanedTasks isAdmin={isAdmin} accessGroups={groups} location="RKV/LINE" setLoading={setLoading}/>}
            {tabValue === 1 &&
                <Grid>
                { flightScheduleError &&
                <Grid style={{ marginTop: '50px' }}>
                    <Typography alignSelf="center" variant='h3' text={"There was an error fetching the flight schedule. Please try again later or contact Core Solutions"} />
                    <Typography variant='h3' text={"This is most likely a CDATA network issue."} />
                </Grid>
                }
                < FlightScheduleView
                    refresh={() => refresh()}
                    flights={flightSchedule}
                    dataFetched={dataFetchedWhen}
                    sortByColumn={(columnName: string, flights: LineWorkPackages[], ascending: boolean) => sortByColumn(columnName, flights, ascending)}
                    ascending={ascending}
                    orderByText={orderByText}
                    isLineSupervisorAdmin={isAdmin}
                />
                </Grid>
            }
            {tabValue === 2 && <Grid> < LineWorkPackageOverview allTails={allTails} setLoadingProp={(loading: boolean) => setLoading(loading)} workPlace={workplaceEnum.rkvLine} /></Grid>}
            {tabValue === 3 && <LiteHandover setLoading={setLoading} workPlace='RVK' allTails={dashTails} />}
        </Grid>
    );
};
export default ReykjavikLineSupervisor;