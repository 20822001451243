import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Paper } from '@mui/material';
import { Typography, Dialog, LinearProgress } from '../../MaterialUiComponents';
import SecurityApi from '../../api/security';
import { SmallPackageInformation } from '../../components';
import { AircraftInHangar } from '../../types/security';
import StaffApi from '../../api/staffmanagement';
import { StaffManagementBays } from '../../types/staffManagement';
import {  HangarChecklistDto } from '../../types/checklist';
import ChecklistDetailsModal from '../Checklists/ChecklistDetailsModal';
import ChecklistsApi from '../../api/checklists';
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";

function HangarOverview() {
  const [hangarOverviewleftCol, setHangarOverviewleftCol] = useState(Array<AircraftInHangar>());
  const [hangarOverviewRightCol, setHangarOverviewRightCol] = useState(Array<AircraftInHangar>());
  const [staffManagementBays, setStaffManagementBays] = useState<StaffManagementBays[]>([]);
  const [checklist, setChecklist] = useState<HangarChecklistDto>();
  const [checklistDialogOpen, setChecklistDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const store = useSelector((state: RootState) => state);
  const isChecklistAdmin = store.user.me.user_clearence_groups.includes("Checklist Edit");

useEffect(() => {
    setLoading(true);
    refresh();
  }, []);

  const refresh = () => {
    SecurityApi.AircraftInHangar().then(res => {
      sortHangarOverview(res.data);
      StaffApi.GetAllStaffManagement().then(res => {
        setStaffManagementBays(res.data);
        setLoading(false);
      });
    });
  }

  const sortHangarOverview = (hangarOverview: AircraftInHangar[]): void => {
  
    const leftCol = hangarOverview.filter((x) => x.bay_name === 'Bay 4' || x.bay_name === 'Bay 5' || x.bay_name === 'Bay 6' || x.bay_name === 'Bay 7');
    const rightCol = hangarOverview.filter((x) => x.bay_name !== 'Bay 4' && x.bay_name !== 'Bay 5' && x.bay_name !== 'Bay 6' && x.bay_name !== 'Bay 7' );

    const sortleft = leftCol.sort((a, b) => a.bay_name.localeCompare(b.bay_name));
    const sortRight = rightCol.sort((a, b) => a.bay_name.localeCompare(b.bay_name));
    
    setHangarOverviewleftCol(sortleft);
    setHangarOverviewRightCol(sortRight);
  }

  const getSupervisorNames = (currentBay: string): string[] =>
    staffManagementBays.reduce<string[]>(
      (acc, bay) =>
        bay.bay.toLowerCase() === currentBay.toLowerCase()
          ? acc.concat(
            (bay.areas.find(area => area.area.toUpperCase().includes("SUPERVISORS"))?.employyes_in_group || []).map(emp => emp.employee_name)
          )
          : acc,
      []
    );

    const getChecklistInfo = (tailNumber: string) => {
      setLoading(true);
      ChecklistsApi.GetChecklist(tailNumber).then(res => {
        if(res.status === true){
          setChecklist(res.checklist);
          setChecklistDialogOpen(true);
        }
        else{
          setLoading(false);
        }
      });
    }

    return (
      <Grid sx={{ maxWidth: '100%', mx: 'auto', px: 2, width: '100%' }}>
        {loading && <LinearProgress />}
        {/* Header */}
        <Typography variant="h1" align="center" text={"Hangar Overview"} />
    
        {/* Main Grid */}
        <Grid
          container
          spacing={1}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap', // Ensures columns stay side by side
            marginTop:1,
          }}
        >
          {/* Left Column */}
          <Grid
            sx={{
              flex: '1 1 48%', // Slightly smaller than 50% to allow extra room
              minWidth: '300px', // Prevents the column from shrinking too small
              maxWidth: 'calc(50% - 8px)', // Accounts for spacing
              paddingRight: 1, // Space between columns
            }}
          >
            {hangarOverviewleftCol.map((row, idx) => (
              <Paper key={idx} sx={{ mb: 1, p: 1 }}>
                <SmallPackageInformation
                  packageInformation={row}
                  supervisors={getSupervisorNames(row.bay_name)}
                  onTailNumberClicked={() => getChecklistInfo(row.tail_number)}
                />
              </Paper>
            ))}
          </Grid>
    
          {/* Right Column */}
          <Grid
            sx={{
              flex: '1 1 48%',
              minWidth: '300px',
              maxWidth: 'calc(50% - 8px)',
              
            }}
          >
            {hangarOverviewRightCol.map((row, idx) => (
              <Paper key={idx} sx={{ mb: 1, p: 1 }}>
                <SmallPackageInformation
                  packageInformation={row}
                  supervisors={getSupervisorNames(row.bay_name)}
                  onTailNumberClicked={getChecklistInfo}
                />
              </Paper>
            ))}
        </Grid>
      </Grid>
      <Dialog
        visible={checklistDialogOpen}
        max_width={"md"}
        title={checklist?.TailNumber || ""}
        context={
          <ChecklistDetailsModal
            checklist={checklist}
            checkListDialogOpen={setChecklistDialogOpen}
            isChecklistAdmin={isChecklistAdmin}
            refreshChecklists={() => {}}
            setLoading={setLoading}
          />
        }
        onClose={(status: Boolean) => setChecklistDialogOpen(false)}
        strict={false}
        show_options={false}
      />
    </Grid>
  );
};
export default HangarOverview;