import { useEffect, useState } from "react";
import BayHandoverAPI from "../../api/bayHandover";
import { Grid, TableCell, TableRow, Button, IconButton } from '@mui/material';
import { Table, Input, Select, Typography } from './../../MaterialUiComponents';
import { BayHandoverIssue } from '../../types/bayHandover';
import { AddCircle, Edit } from '@mui/icons-material';
import EditColumn from './../Security/EditColumn';
import { makeStyles } from '@mui/styles';
import taskApi from './../../api/tasks';
import { EmployeeSearch } from "../../components/EmployeeSearch";
import { DateTime } from 'luxon';
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    save: {
        color: 'green'
    },
}));

const BayHandover = () => {
    const store = useSelector((state: RootState) => state);
    const isBayHandoverAdmin = store.user.me.user_clearence_groups.includes("Bay Handover Edit");
    const [issues, setIssues] = useState<BayHandoverIssue[]>([]);
    const [filteredIssues, setFilteredIssues] = useState<BayHandoverIssue[]>([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [allTails, setAllTails] = useState<string[]>([]);
    const [selectedTailNumber, setSelectedTailNumber] = useState<string>("");
    const [selectedIssue, setSelectedIssue] = useState<string>("");
    const [selectedLocation, setSelectedLocation] = useState<string>("");
    const [createIssue, setCreateIssue] = useState<boolean>(false);

    const [tailSearch, setTailSearch] = useState<string>("");
    const [employeeSearch, setemployeeSearch] = useState<number>(-1);
    const [locationSearch, setLocationSearch] = useState<string>("");

    const [filter, setFilter] = useState<boolean>(false);

    useEffect(() => {
        onSearch();
    }, [tailSearch, employeeSearch, locationSearch]);

    useEffect(() => {
        refresh();
    }, []);

    const classes = useStyles();

    const refresh = () => {
        setFilter(false);
        setCreateIssue(false);
        setSelectedTailNumber("");
        setSelectedIssue("");
        setEditIndex(-1);
        taskApi.GetAllTailNumbers().then(res => {
            setAllTails(res.data);
        })
        BayHandoverAPI.GetAllBayHandOverIssues().then(res => {
            setIssues(res.data);
        })
    }
    const onSelectRow = (row: BayHandoverIssue, index: number) => {
        setEditIndex(index);
        setSelectedIssue(row.issue);
        setSelectedTailNumber(row.tail_number);
    }

    const generateTableBody = (row: BayHandoverIssue, index: number) => {
        const user = store.employees.all_employees.find(s => row.created_by === s.user_id);
        return (
            editIndex === index ?
                <TableRow className={index % 2 ? classes.evenRows : ''} >
                    <TableCell>{DateTime.fromISO(`${row.created_date}`).toFormat('d LLL yyyy')}</TableCell>
                    <TableCell>{user?.name}</TableCell>
                    <TableCell>
                        <Select
                            width={100}
                            widthPercentage
                            filter_by_text="Tail number"
                            onSelectedItem={(e: any) => setSelectedTailNumber(e.target.value)}
                            options={allTails}
                            selectedItem={selectedTailNumber}
                        />
                    </TableCell>
                    <TableCell>
                        <Input
                            multiline={true}
                            help_text={""}
                            label_text={"Issue"}
                            value={selectedIssue}
                            onTextChange={(text: string) => setSelectedIssue(text)}
                            type="text"
                            width={100}
                        />
                    </TableCell>
                    <TableCell>
                        <Select
                            width={100}
                            widthPercentage
                            filter_by_text="Location"
                            onSelectedItem={(e: any) => setSelectedLocation(e.target.value)}
                            options={["BAY 1", "BAY 2", "BAY 3", "BAY 4", "BAY 5", "BAY 6", "Bay 7", "Outside", "Other"]}
                            selectedItem={selectedLocation}
                        />
                    </TableCell>
                    <EditColumn
                        abort={true}
                        index={index}
                        saveChanges={() => onModifieIssue(row.id)}
                        editRow={index}
                        setEditRow={() => { setEditIndex(-1) }}
                    />
                </TableRow>
                :
                <TableRow className={index % 2 ? classes.evenRows : ''} >
                    <TableCell>{(row.modified_date_text !== undefined && row.modified_date_text !== null && row.modified_date_text !== "") ? row.modified_date_text : row.created_date_text}</TableCell>
                    <TableCell>{user?.name}</TableCell>
                    <TableCell>{row.tail_number}</TableCell>
                    <TableCell>{row.issue}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{isBayHandoverAdmin && <IconButton onClick={() => onSelectRow(row, index)}><Edit /></IconButton>}</TableCell>
                </TableRow>
        )
    }

    const onAddIssue = () => {
        BayHandoverAPI.AddIssue(selectedLocation, selectedIssue, selectedTailNumber).then(res => {
            refresh();
        })
    }

    const onModifieIssue = (issueId: number) => {
        BayHandoverAPI.UpdateIssue(issueId, selectedIssue, selectedTailNumber, selectedLocation).then(res => {
            refresh();
        })
    }

    const filterIssuesByProperties = (employee: number, location: string, tail: string) => {
        return issues.filter(issue => {
            // Check if the properties match the given values (if provided)
            return (
                (employee === -1 || issue.created_by === employee) &&
                (location === '' || issue.location === location) &&
                (tail === '' || issue.tail_number === tail)
            );
        });
    }

    const onSearch = () => {
        const tempIssues = filterIssuesByProperties(employeeSearch, locationSearch, tailSearch);
        setFilteredIssues(tempIssues);
        setFilter(true);
    }

    const onClearFilters = () => {
        setFilteredIssues([]);
        setTailSearch("");
        setemployeeSearch(-1);
        setLocationSearch("");
        setFilter(false);
    }

    return (
        <Grid>
            <Typography text={'Bay Handover'} variant='h1' align="center" />
            <Grid>
                <Grid container direction="row" justifyContent="center">
                    <Grid style={{ marginRight: '18px' }} item xs={2} md={2} lg={2} xl={2}>
                        <Select
                            width={100}
                            widthPercentage
                            filter_by_text="Location"
                            onSelectedItem={(e: any) => setLocationSearch(e.target.value)}
                            options={["BAY 1", "BAY 2", "BAY 3", "BAY 4", "BAY 5","BAY 6", "Bay 7", "Outside", "Other"]}
                            selectedItem={locationSearch}
                        />
                    </Grid>
                    <Grid style={{ marginRight: '18px' }} item xs={2} md={2} lg={2} xl={2}>
                        <Select
                            width={100}
                            widthPercentage
                            filter_by_text="Tail number"
                            onSelectedItem={(e: any) => setTailSearch(e.target.value)}
                            options={allTails}
                            selectedItem={tailSearch}
                        />
                    </Grid>
                    <Grid style={{ marginRight: '18px' }} item xs={3} md={3} lg={3} xl={3}>
                        <EmployeeSearch
                            customCloseFunction={() => setemployeeSearch(-1)}
                            place_holder={"Write employee name"}
                            onSelectedEemployee={(employeeId: number) => setemployeeSearch(employeeId)}
                        />
                    </Grid>
                    <Grid style={{ marginRight: '18px', marginTop: '8px' }} item xs={2} md={2} lg={2} xl={2}>
                        <Button variant="contained" color="primary" onClick={() => onClearFilters()}>Clear filters</Button>
                    </Grid>
                </Grid>
                {isBayHandoverAdmin && <Grid container direction="column" alignItems="center" style={{ marginTop: '28px' }}>
                    <IconButton onClick={() => { setCreateIssue(true); setSelectedTailNumber(""); setSelectedLocation("") }} className={classes.save} >
                        <AddCircle />
                    </IconButton>
                </Grid>}
                {createIssue &&
                    <Grid container direction="row" justifyContent="center" style={{ marginTop: '28px' }}>
                        <Grid style={{ marginRight: '18px' }} item xs={3} md={3} lg={3} xl={3}>
                            <Input multiline={true} help_text={""} label_text={"Issue"} value={selectedIssue} onTextChange={(text: string) => setSelectedIssue(text)} type="text" width={100} />
                        </Grid>
                        <Grid style={{ marginRight: '18px' }} item xs={2} md={2} lg={2} xl={2}>
                            <Select width={100} widthPercentage filter_by_text="Tail number" onSelectedItem={(e: any) => setSelectedTailNumber(e.target.value)} options={allTails} selectedItem={selectedTailNumber} />
                        </Grid>
                        <Grid style={{ marginRight: '18px' }} item xs={2} md={2} lg={2} xl={2}>
                            <Select width={100} widthPercentage filter_by_text="Location" onSelectedItem={(e: any) => setSelectedLocation(e.target.value)} options={["BAY 1", "BAY 2", "BAY 3", "BAY 4", "BAY 5", "BAY 6", "Bay 7", "Outside", "Other"]} selectedItem={selectedLocation} />
                        </Grid>
                        <Grid style={{ marginTop: '8px' }} item xs={1} md={1} lg={1} xl={1}>
                            <Button variant="contained" color="primary" onClick={() => onAddIssue()}>Add issue</Button>
                        </Grid>
                        <Grid style={{ marginRight: '18px', marginTop: '8px' }} item xs={1} md={1} lg={1} xl={1}>
                            <Button variant="contained" color="secondary" onClick={() => setCreateIssue(false)}>Abort</Button>
                        </Grid>
                    </Grid>
                }
                <Table
                    headers={["Date", "Owner", "Tail", "Issue", "Location", ""]}
                    rows={filter === true ? filteredIssues : issues}
                    generateBody={(row: any, index: number) => generateTableBody(row, index)}
                    pagination={true}
                    defaultRowsPerPage={10}
                    skipIndexCol={true}
                />
            </Grid>
        </Grid >
    )
}

export default BayHandover;