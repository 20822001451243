import { useEffect, useState } from "react";
import { Grid, TableCell, TableRow, Button, IconButton, Checkbox } from '@mui/material';
import { Table, Input, Select, Typography, DatePicker, Dialog, LinearProgress } from '../../MaterialUiComponents';
import { AddCircle, Edit, Search, Check } from '@mui/icons-material';
import EditColumn from '../Security/EditColumn';
import { makeStyles } from '@mui/styles';
import taskApi from '../../api/tasks';
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import ChecklistApi from "../../api/checklists";
import { HangarChecklistDto } from "../../types/checklist";
import ChecklistDetailsModal from "./ChecklistDetailsModal";
import { DateTime } from "luxon";
import { TableColumns } from "../../types/table";

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    save: {
        color: 'green'
    },
}));

interface selectedVariables {
    tailNumber: string;
    description: string;
    status: boolean;
    inDate: string;
    outDate: string;
 }

const sortTableColumns = [
    { label: "Created Date", key: "created_date" },
    { label: "Tail Number", key: "tail_number" },
    { label: "Description", key: "description" },
    { label: "Active", key: "is_active" },
    { label: "In Date", key: "input_date" },
    { label: "Out Date", key: "output_date" },
    { label: "Edit", key: "" },
    { label: "", key: "" }
] as TableColumns[];

const Checklists = () => {
    const store = useSelector((state: RootState) => state);
    const isChecklistAdmin = store.user.me.user_clearence_groups.includes("Checklist Edit");
    const [checklists, setChecklists] = useState<HangarChecklistDto[]>([]);
    const [filteredChecklists, setFilteredChecklists] = useState<HangarChecklistDto[]>([]);
    const [allTails, setAllTails] = useState<string[]>([]);
    
    const [selected, setSelected] = useState<selectedVariables>({tailNumber: "", description: "", status: false, inDate: "", outDate: ""});
    const [selectedFilters, setSelectedFilters] = useState<selectedVariables>({tailNumber: "", description: "", status: false, inDate: "", outDate: ""});

    const [selectedChecklist, setSelectedChecklist] = useState<HangarChecklistDto | undefined>(undefined);
    const [createChecklist, setCreateChecklist] = useState<boolean>(false);
    const [dialogText, setDialogText] = useState<string>("");
    
    const [checklistDialogOpen, setChecklistDialogOpen] = useState<boolean>(false);
    const [deleteChecklistDialogOpen, setDeleteChecklistDialogOpen] = useState<boolean>(false);
    
    const [editRow, setEditRow] = useState(-1);
    const [loading, setLoading] = useState<boolean>(false);
    const classes = useStyles();
    
    useEffect(() => {
        refresh();
    }, []);


    const refresh = () => {
        setLoading(true);
        setSelected(prevState => ({
            ...prevState,
            tailNumber: "",
            description: "",
            status: false,
          }));
        setEditRow(-1);
        setCreateChecklist(false);
        taskApi.GetAllTailNumbers().then(res => {
            setAllTails(res.data);
            ChecklistApi.GetAllChecklists().then(res => {
                setChecklists(res.data);
                setLoading(false);
            });
        });
    }

    const onSelectRow = (row: HangarChecklistDto, index: number) => {
        setCreateChecklist(false);
        setEditRow(index);
        setSelected(prevState => ({
            ...prevState,
            tailNumber: row.TailNumber,
            description: row.Description,
            status: row.IsActive,
            inDate: row.InputDate,
            outDate: row.OutputDate,
          }));
    }

    const onModifyChecklist = (checklistID: number) => {
        setLoading(true);
        ChecklistApi.UpdateChecklist(checklistID, selected.tailNumber, selected.description, selected.status, new Date(selected.inDate), new Date(selected.outDate)).then(res => {
            refresh();
        })
    }

    const selectChecklist = (checklist: HangarChecklistDto) => {
        const text = checklist.TailNumber + " " + checklist.Description;
        setDialogText(text);
        setSelectedChecklist(checklist);
        setChecklistDialogOpen(true);
    }

    const onDeleteDialog = (checklist: HangarChecklistDto) => {
        setSelectedChecklist(checklist);
        setDeleteChecklistDialogOpen(true);
    }

    const onDeleteChecklist = () => {
        setLoading(true);
        if(selectedChecklist === undefined) {return;}
        else{
            ChecklistApi.DeleteChecklist(selectedChecklist.Id).then(res => {        
                setDeleteChecklistDialogOpen(false);
                refresh();
            });
        }
    }

    const generateTableBody = (row: HangarChecklistDto, index: number) => {
        return (editRow === index ?
                <TableRow className={index % 2 ? classes.evenRows : ''} >
                    <TableCell>{DateTime.fromISO(row.CreatedDate).toFormat('yyyy.MM.dd')}</TableCell>
                    <TableCell>
                        <Select
                            width={100}
                            widthPercentage
                            filter_by_text="Tail number"
                            onSelectedItem={(e: React.ChangeEvent<HTMLSelectElement>) => 
                                setSelected(prevState => ({
                                    ...prevState,
                                    tailNumber: e.target.value,
                                  }))
                            }
                            options={allTails}
                            selectedItem={selected.tailNumber}
                        />
                    </TableCell>
                    <TableCell>
                        <Input
                            multiline={true}
                            help_text={""}
                            label_text={"Description"}
                            value={selected.description}
                            onTextChange={(text: string) => 
                                setSelected(prevState => ({
                                    ...prevState,
                                    description: text,
                                  }))
                                }
                            type="text"
                            width={100}
                        />
                    </TableCell>
                    <TableCell width="20">
                        <Checkbox 
                            color="primary" 
                            disabled={editRow === index ? false : true} 
                            checked={editRow === index ? selected.status : row.IsActive} 
                            onChange={  () =>  
                                 setSelected(prevState => ({
                                    ...prevState,
                                    status: !prevState.status,
                                }))
                            } 
                        />
                        </TableCell>
                    <TableCell width={130}>
                        <DatePicker 
                            type="DateAndTime" 
                            selected={new Date(selected.inDate)} 
                            label="Time" 
                            onDateChange={(date: Date) => { 
                                setSelected(prevState => ({
                                        ...prevState,
                                        inDate: date.toISOString(),
                                    }))
                                }
                            }
                        />
                    </TableCell>
                    <TableCell width={130}>
                        <DatePicker 
                            type="DateAndTime" 
                            selected={new Date(selected.outDate)} 
                            label="Time" 
                            onDateChange={(date: Date) => { 
                               setSelected(prevState => ({
                                    ...prevState,
                                    outDate: date.toISOString(),
                                }))
                            }} 
                        />
                        </TableCell>
                    <EditColumn
                        abort={true}
                        index={index}
                        saveChanges={() => onModifyChecklist(row.Id)}
                        editRow={index}
                        setEditRow={() => { setEditRow(-1) }}
                        deleteRow={() => onDeleteDialog(row)} 
                    />
                    <TableCell></TableCell>
                </TableRow>
                :
                <TableRow className={index % 2 ? classes.evenRows : ''} >
                    <TableCell>{DateTime.fromISO(row.CreatedDate).toFormat('yyyy.MM.dd')}</TableCell>
                    <TableCell>{row.TailNumber}</TableCell>
                    <TableCell>{row.Description}</TableCell>
                    <TableCell width="20">{row.IsActive === true ? <Check style={{ color: 'green' }} /> : ""}</TableCell>
                    <TableCell>{DateTime.fromISO(row.InputDate).toFormat('yyyy.MM.dd HH:mm')}</TableCell>
                    <TableCell>{DateTime.fromISO(row.OutputDate).toFormat('yyyy.MM.dd HH:mm')}</TableCell>
                    <TableCell>{isChecklistAdmin && <IconButton onClick={() => onSelectRow(row, index)}><Edit /></IconButton>}</TableCell>
                    <TableCell>{isChecklistAdmin && <IconButton onClick={() => selectChecklist(row)}><Search color="primary" /></IconButton>}</TableCell>
                </TableRow>
        )
    }

    const CreateChecklist = () => {
        setLoading(true);
        setCreateChecklist(false);
        ChecklistApi.CreateChecklist(selected.tailNumber, selected.description, selected.status, new Date(selected.inDate), new Date(selected.outDate)).then(res => {
            refresh();
        });
    }

    const applyFilters = () => {
        let filteredChecklists = checklists;
        if (selectedFilters.tailNumber !== "") {
            filteredChecklists = filteredChecklists.filter(x => x.TailNumber === selectedFilters.tailNumber);
        }
        if (selectedFilters.description !== "") {
            filteredChecklists = filteredChecklists.filter(x => x.Description === selectedFilters.description);
        }
        if (selectedFilters.inDate !== "" && selectedFilters.outDate !== "") {
            filteredChecklists = filteredChecklists.filter(x => {
            const inDate = new Date(x.InputDate).getTime();
            const outDate = new Date(x.OutputDate).getTime();
            const selectedIn = new Date(selectedFilters.inDate).getTime();
            const selectedOut = new Date(selectedFilters.outDate).getTime();
            return (inDate >= selectedIn && outDate <= selectedOut);
            });
        }
        if (selectedFilters.status !== undefined && selectedFilters.status !== null) {
            filteredChecklists = filteredChecklists.filter(x => x.IsActive === selectedFilters.status);
        }
        setFilteredChecklists(filteredChecklists);
    }

    const clearFilters = () => {
        setSelectedFilters(prevState => ({
            ...prevState,
            tailNumber: "",
            description: "",
            status: false,
            inDate: "",
            outDate: "",
        }))
        setFilteredChecklists([]);
    }

    const initializeChecklist = () => {
        setSelected(prevState => ({
            ...prevState,
            tailNumber: "",
            description: "",
            status: false,
            inDate: new Date().toISOString(),
            outDate: new Date().toISOString(),
        }))
    }

    return (
        <Grid>
            {loading && <LinearProgress />}
            <Typography text={'Checklists'} variant='h1' align="center" />
            <Grid container spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                <Grid item sm={1} >
                    <Select
                        width={100}
                        widthPercentage
                        filter_by_text="Tail number"
                        onSelectedItem={(e: React.ChangeEvent<HTMLSelectElement>) => 
                            setSelectedFilters(prevState => ({
                                ...prevState,
                                tailNumber: e.target.value,
                            }))
                        }
                        options={allTails}
                        selectedItem={selectedFilters.tailNumber}
                    />
                </Grid>
                <Grid item sm={2} >
                    <Input
                        multiline={false}
                        help_text={""}
                        label_text={"Description"}
                        value={selectedFilters.description}
                        onTextChange={(text: string) => 
                            setSelectedFilters(prevState => ({
                                ...prevState,
                                description: text,
                            }))
                        }
                        type="text"
                        width={100}
                    />
                </Grid>
                <Grid item sm={1} >
                    <DatePicker
                        type="DateAndTime"
                        selected={selectedFilters.inDate ? new Date(selectedFilters.inDate) : null}
                        label="In Date"
                        onDateChange={(date: Date) => 
                            setSelectedFilters(prevState => ({
                                ...prevState,
                               inDate: date.toISOString(),
                            }))
                        }
                    />
                </Grid>
                <Grid item sm={1} >
                    <DatePicker
                        type="DateAndTime"
                        selected={selectedFilters.outDate ? new Date(selectedFilters.outDate) : null}
                        label="Out Date"
                        onDateChange={(date: Date) => 
                            setSelectedFilters(prevState => ({
                                ...prevState,
                                outDate: date.toISOString(),
                            }))
                        }
                    />
                </Grid>
                <Grid item sm={0.5} style={{marginTop: '8px'}}>
                    <Checkbox
                        color="primary"
                        checked={selectedFilters.status}
                        onChange={() => 
                            setSelectedFilters(prevState => ({
                                ...prevState,
                                status: !prevState.status,
                            }))
                        }
                    />
                </Grid>
                <Grid item sm={1.5} style={{marginTop: '8px'}}>
                    <Button variant="contained" color="primary" onClick={() => applyFilters()}>
                        Apply Filters
                    </Button>
                </Grid>
                <Grid item sm={1.5} style={{marginTop: '8px'}}>
                    <Button variant="contained" color="primary" onClick={() => clearFilters()}>
                        Clear Filters
                    </Button>
                </Grid>
            </Grid>
            {isChecklistAdmin && <Grid container direction="column" alignItems="center" style={{ marginTop: '28px' }}>
                <IconButton 
                    onClick={() => { setCreateChecklist(true); initializeChecklist(); setEditRow(-1)}} 
                    className={classes.save} 
                >
                    <AddCircle />
                </IconButton>
            </Grid>}
            {createChecklist &&
                <Grid container direction="row" justifyContent="center" style={{ marginTop: '28px' }}>
                    <Grid style={{ marginRight: '18px' }} item xs={1} md={1} lg={1} xl={1}>
                        <Select 
                            width={100} 
                            widthPercentage 
                            filter_by_text="Tail number" 
                            onSelectedItem={(e: React.ChangeEvent<HTMLSelectElement>) => 
                                setSelected(prevState => ({
                                    ...prevState,
                                    tailNumber: e.target.value,
                                  }))
                            } 
                            options={allTails} 
                            selectedItem={selected.tailNumber} 
                        />
                    </Grid>
                    <Grid style={{ marginRight: '18px' }} item xs={3} md={3} lg={3} xl={3}>
                    <Input
                        multiline={false}
                        help_text={""}
                        label_text={"Description"}
                        value={selected.description}
                        onTextChange={(text: string) => 
                            setSelected(prevState => ({
                                ...prevState,
                                description: text,
                            }))
                        }
                        type="text"
                        width={100}
                    />
                    </Grid>
                    <Grid style={{ marginRight: '18px' }} item xs={2} md={2} lg={2} xl={2}>
                        <DatePicker 
                            type="DateAndTime" 
                            selected={new Date(selected.inDate)} 
                            label="Time" 
                            onDateChange={(date: Date) => { 
                                setSelected(prevState => ({
                                    ...prevState,
                                    inDate: date.toISOString(),
                                  }))
                                }
                            } 
                        />
                    </Grid>
                    <Grid style={{ marginRight: '18px' }} item xs={2} md={2} lg={2} xl={2}>
                        <DatePicker 
                            type="DateAndTime" 
                            selected={new Date(selected.outDate)} 
                            label="Time" 
                            onDateChange={(date: Date) => { 
                                setSelected(prevState => ({
                                    ...prevState,
                                    outDate: date.toISOString(),
                                  }))
                                }
                            }
                        />
                    </Grid>

                    <Grid style={{ marginTop: '8px' }} item xs={1} md={1} lg={1} xl={1}>
                        <Button variant="contained" color="primary" onClick={() => CreateChecklist()}>Save</Button>
                    </Grid>
                    <Grid style={{ marginRight: '18px', marginTop: '8px' }} item xs={1} md={1} lg={1} xl={1}>
                        <Button variant="contained" color="secondary" onClick={() => setCreateChecklist(false)}>Abort</Button>
                    </Grid>
                </Grid>
            }
            <Grid>
                <Table
                    headers={["Created Date", "Tail Number", "Description", "Active", "In Date", "Out Date", "Edit", ""]}
                    rows={(filteredChecklists.length !== 0) ? filteredChecklists : checklists}
                    generateBody={(row: HangarChecklistDto, index: number) => generateTableBody(row, index)}
                    pagination={true}
                    defaultRowsPerPage={10}
                    skipIndexCol={true}
                    sortTableColumns={sortTableColumns}
                />
            </Grid>
            <Dialog
                visible={checklistDialogOpen}
                max_width={"md"}
                title={dialogText}
                context={
                    <ChecklistDetailsModal 
                        checklist={selectedChecklist} 
                        checkListDialogOpen={setChecklistDialogOpen} 
                        isChecklistAdmin={isChecklistAdmin} 
                        refreshChecklists={refresh} 
                        setLoading={setLoading} 
                    />
                }
                onClose={(status: Boolean) => setChecklistDialogOpen(false)}
                strict={false}
                show_options={false}
            />
            <Dialog
                visible={deleteChecklistDialogOpen}
                max_width={"sm"}
                title={"Are you sure you want to delete this checklist, it is not the same as marking it as done/inactive?"}
                context={<></>} 
                onClose={(status: Boolean) =>  {status === true ? onDeleteChecklist() : setDeleteChecklistDialogOpen(false)}}
                strict={false}
                show_options={true}
            />
        </Grid >
    )
}

export default Checklists;